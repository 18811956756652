import React from 'react';

import HamburgerIcon from './HamburgerIcon'
;
import { Container, Navbar, NavbarBrand, NavbarToggler,  } from 'reactstrap';
import Link from '../net/Link';

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    /*
    if (this.props.menu_open) {
      this.props.actions.close_menu();
    } else {
      this.props.actions.open_menu();
    }
    */
   this.props.onMenuToggle(! this.props.menu_open);
  }

  render() {

    let togglerClassname = "";
//    if (this.props.menu_open) {
 //     togglerClassname = "invisible";
 //   }

    return (
      <Navbar fixed="top">
        <Container fluid  className="w-80 pl-0 pr-0">
        <NavbarBrand to="/" tag={Link}><img src="/Fwends-Logo.png" className="logo" alt="Fwends Logo"/></NavbarBrand>
        <NavbarToggler onClick={this.toggleMenu} className={togglerClassname}><HamburgerIcon /></NavbarToggler>
        </Container>
        </Navbar>
    )
  }

}
/*
function mapStateToProps(state, ownProps) {
  
  return {
    menu_open: state.menu.menu_open
  }
}

  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators(actions, dispatch)
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
*/
export default NavBar;