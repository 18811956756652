
import React from 'react';
import { Link as GatsbyLink } from "gatsby";

class Link extends React.Component {


  render() {

    const { to, children, ...rest } = this.props;

    let isexternal = to.startsWith('http') || to === '';

    if (isexternal) {
      return (
        <a href={to} {...rest}>{children}</a>
      )
    } else {
      return (
        <GatsbyLink to={to} {...rest}>{children}</GatsbyLink>
      )
    }

  }

}

export default Link;