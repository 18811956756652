import React from 'react';

class HamburgerIcon extends React.Component {
  render(){
    return (

<svg width="27px" height="22px" viewBox="0 0 27 24" version="1.1" xmlns="http://www.w3.org/2000/svg" aria-labelledby="open-title" role="img">
    <title id="open-title">Open Menu</title>
    <g id="Fwends-Hamburger" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Front-scroll-simulation" transform="translate(-1448.000000, -147.000000)" fill="#282828">
            <g id="Page-1" transform="translate(1448.000000, 147.000000)">
                <polygon id="Fill-1" points="0 3.0001 26.745 3.0001 26.745 0.0001 0 0.0001"></polygon>
                <polygon id="Fill-2" points="0 13.1751 26.745 13.1751 26.745 10.1741 0 10.1741"></polygon>
                <polygon id="Fill-3" points="0 23.3491 26.745 23.3491 26.745 20.3491 0 20.3491"></polygon>
            </g>
        </g>
    </g>
</svg>
    )
  }
}

export default HamburgerIcon;