import React from 'react';

class CloseMenuIcon extends React.Component {


  render() {
    return (

<svg width="28px" height="27px" viewBox="0 0 28 27" version="1.1" xmlns="http://www.w3.org/2000/svg" aria-labelledby="close-title" role="img">
    <title id="close-title">Close Menu</title>
    <g id="Fwends-Close" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Menu" transform="translate(-1447.000000, -86.000000)" fill="#FFFFFF">
            <g id="Group-17" transform="translate(1447.000000, 86.000000)">
                <polygon id="Fill-2-Copy-3" transform="translate(14.000000, 13.500000) rotate(45.000000) translate(-14.000000, -13.500000) " points="-3 15 31 15 31 12 -3 12"></polygon>
                <polygon id="Fill-2-Copy-3" transform="translate(14.000000, 13.500000) rotate(-45.000000) translate(-14.000000, -13.500000) " points="-3 15 31 15 31 12 -3 12"></polygon>
            </g>
        </g>
    </g>
</svg>
    )
  }
}

export default CloseMenuIcon;