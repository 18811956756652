
import React from "react"
import '../styles/index.scss';

import Sidebar from '../components/Sidebar';
import NavBar from '../components/NavBar';
import ScrollingEff from '../components/ScrollingEff';
import CookieNotice from '../components/CookieNotice';

import Cookies from 'js-cookie';

import { initAnalytics, logPageView } from '../utils/analytics';

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.handleMenuToggle = this.handleMenuToggle.bind(this);
    this.handleAckCookie = this.handleAckCookie.bind(this);

    this.state = {
      menu_open: false,
      showCookieNotice: false
    }
  }

  handleMenuToggle(open) {
    this.setState({menu_open: open});
  }

  handleAckCookie() {
    Cookies.set('cookie-notice-acknowledged', 'yes', { expires: 364 });
    this.setState({showCookieNotice: false});
    initAnalytics();
    logPageView();

  }
  componentDidMount() {
    if (Cookies.get('cookie-notice-acknowledged') === 'yes') {
      this.setState({showCookieNotice: false});
      initAnalytics(); //this will set the cookies on the client.
      logPageView();
    } else {
      this.setState({showCookieNotice: true});
    }
  }


  render() {
    let {children} = this.props;
  return (
    <div id="outer-container">

        <Sidebar menu_open={this.state.menu_open}
        onMenuToggle={this.handleMenuToggle} />
        

        <NavBar menu_open= {this.state.menu_open}
        onMenuToggle={this.handleMenuToggle}/>

        <main id="page-wrap">
          {/*
            <ScrollingEff />
  */ }
            {children}
      </main>
      {this.state.showCookieNotice ? <CookieNotice onAckCookie={this.handleAckCookie}/> : null }
        
      </div>

  )
  }

};

export default Layout;
