import React from 'react';


const idleTime = 1500; //time before reset-animation kicks in

const speed = 5; //animation speed, degrees to rotate per scroll event


const blue = { r: 75, g: 100, b:255 };	
const yellow = { r: 255, g: 255, b: 65 };

//const strokeWidth=10;


//let smileArc = "";


const resetAnimationInterval = 20;



/* ************************************************************************************

  Construction of the Eff:

  The Eff consists of two lines (one vertical, one horizontal) and a 60° arc.

  The arc is constructed by positioning a virtual circle at the horizontal middle
  of the graphic, opening a radius, and then taking a 60° part of the resulting
  circle.

  The hinge point of the two lines is positioned relative to the circle center,
  with an offset both to the left and to the bottom.

 ************************************************************************************ */


// General Parameters
// ================================================================================== //
let stroke = 2.923; //stroke width down stroke and arc
let strokeTop = 2.57; //the horizontal top stroke is thinner than the rest.
let strokeAngle = strokeTop; //the stroke of the angle
//note: the value below is what I measure from the source, but it appears to
//be too narrow
//let strokeAngle = 2.435; //the stroke of the angle


// Constructing the circle arc
// ================================================================================== //
let d = 33.697; // circle diameter
let r = d / 2;

let arc = 60; //60°
let arc_start =  180 - (arc / 2);
let arc_end = 180 + (arc / 2);

let cX = r + (stroke / 2); //circle x coord
let cY = r + (stroke / 2); // circle y coord

let smileArc = describeArc(cX,cY,r,arc_start, arc_end); //the actual arc SVG definition


// Constructing the F
// ================================================================================== //

// The F is not centered on the circle, but offset to the left and bottom.
// Reference point is the top of the downstroke

let f_offset_x = -2;
let f_offset_y = 4.62

let f_height = 23.985 - (stroke / 2);
let f_width = 14.771 - (stroke / 2);

/*
  Constructing the lines:
  Since we have different stroke widths for down stroke and side stroke,
  we use two lines.
  The downstroke is from x0/y0 to x1/y1, and the side stroke is x1mod/y1mod x2/y2mod
  The mod is needed to accomodate the differences in strokewidth.
*/

let x0 = r + f_offset_x + (stroke / 2);
let y0 = r + f_offset_y + f_height + (stroke / 2);

let x1 = x0;
let y1 = r + f_offset_y + (stroke / 2);

let x2 = x1 + f_width;
let y2 = y1;

let x1mod = x1 - (stroke/2);
let y1mod = y1 - (stroke - strokeTop);
let y2mod= y2 - (stroke - strokeTop);


// Setting the View Port
// ================================================================================== //

// Depending on wether we want to rotate the arc, we need a larger or smaller viewport

// this is the height of the total construct, that allows for rotation
let svg_width = d + stroke;
let svg_height = r + f_offset_y + f_height + (stroke / 2);

// if we aren't rotating the arc, we can be much smaller:
//let b_x = polarToCartesian(cX,cY,r,arc_start =  180 + (arc / 2)).x - stroke / 4 ;
//let b_y = r + f_offset_y;
//let b_width = x0 - b_x + f_width;
//let b_height = f_height + stroke/2;

let viewbox = "";

// This is the standard viewbox for rotation:
viewbox = "0 0 " + svg_width + " " + svg_height;

// Want the small one? No problem! Remove the comment from that line
//viewbox = b_x + " " + b_y + " " + b_width + " " + b_height;



// Two helpers translating a radius / arc to cartesian coordinates
// ================================================================================== //
function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  var angleInRadians = (angleInDegrees-90) * Math.PI / 180.0;

  return {
    x: centerX + (radius * Math.cos(angleInRadians)),
    y: centerY + (radius * Math.sin(angleInRadians))
  };
}

function describeArc(x, y, radius, startAngle, endAngle){

    var start = polarToCartesian(x, y, radius, endAngle);
    var end = polarToCartesian(x, y, radius, startAngle);

    var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    var d = [
        "M", start.x, start.y, 
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(" ");

    return d;       
}






class ScrollingEff extends React.Component {
  constructor(props) {
    super(props);

    this._timeout = null;
    this._resettimeout = null;

    this.handleScroll = this.handleScroll.bind(this);
    this.resetComponent = this.resetComponent.bind(this);

    this.getSmileColor = this.getSmileColor.bind(this);

    this.moveToStart = this.moveToStart.bind(this);
    //smileArc = this.describeArc(cX,cY,r,157.5, 202.5);




    this.state = {previousScrollPosition: 0, rotation: 0,
      smileColor: blue, hasManuallyScrolled : false
    };
  }

  getSmileColor(rotation) {
  let distanceTravelled = Math.abs(rotation / 180);
  let newColors = {};
    if (distanceTravelled > 1 ) { //181° to 360°
      newColors = {
        r: yellow.r - (Math.abs(yellow.r - blue.r) * (distanceTravelled - 1)),
        g: yellow.g - (Math.abs(yellow.g - blue.g) * (distanceTravelled - 1)),
        b: yellow.b + (Math.abs(yellow.b - blue.b) * (distanceTravelled - 1))
      }
    } else { // 0° to 180°
      newColors = {
        r: blue.r + (Math.abs(yellow.r - blue.r) * distanceTravelled),
        g: blue.g + (Math.abs(yellow.g - blue.g) * distanceTravelled),
        b: blue.b - (Math.abs(yellow.b - blue.b) * distanceTravelled)
      }

    }
    return newColors;
  }

  resetComponent() {
    let b = this.state.rotation; //beginning value
    //how many steps do we need to get from b to 0?
    let c = Math.abs(b-360);//change in value
    let d = Math.ceil(c / speed); //duration
    let easing = {b: b, c: c, d: d};
    this.setState({counter: 0 , easing: easing});
    this.moveToStart();
  }

  moveToStart() {
    if (this.state.easing) {

    
    let t = this.state.counter;    
    let d = this.state.easing.d;
    let c = this.state.easing.c;
    let b = this.state.easing.b;
    let val = 0;
    // see https://github.com/danro/jquery-easing/blob/master/jquery.easing.js
    // for formulas
    if ((t/=d/2) < 1){ //eslint-disable-line no-cond-assign
      
      val = (c/2*t*t + b);
    } else {
      val = ( -c/2 * ((--t)*(t-2) - 1) + b);
    }


    let newRot = val;
    if (newRot < 0) newRot = 0;
    if (newRot > 360) newRot = 360;
    let counter = this.state.counter + 1;

    if (newRot > 0 && newRot < 360) {
    this._resettimeout = setTimeout(() => {
      this.moveToStart();
    }, resetAnimationInterval);

    }
  
  this.setState({rotation: newRot, counter: counter});
}
  }





  handleScroll(e) {

    e.preventDefault();
    //if there's a timeout, clear it because we're moving
    if (this._timeout) {
      clearTimeout(this._timeout);
    }
    if (this._resettimeout) {
      clearTimeout(this._resettimeout);
    }

    /*
    If the user reloads the page and the browser remembers the old 
    position on the page, it fires a scrolling event. to prevent moving the logo
    without user intervention we surpress this event if there never was a manual scroll before
    */
    if (this.state.hasManuallyScrolled) {

      //let clientHeight = document.body.clientHeight;
      //Safari wants document.body, Chrome document.documentElement ... sigh ...
      let scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
      let newRotation = this.state.rotation;
      if (scrollPosition > this.state.previousScrollPosition) {
        newRotation = (this.state.rotation - speed) % 360;
      } else {
        newRotation = (this.state.rotation + speed) % 360;
      }

      if (newRotation < 0 ){
        newRotation = newRotation + 360;
      }

      let distanceTravelled = Math.abs(newRotation / 180);
      let newColors = {};
      if (distanceTravelled > 1 ) { //181° to 360°
        newColors = {
          r: yellow.r - (Math.abs(yellow.r - blue.r) * (distanceTravelled - 1)),
          g: yellow.g - (Math.abs(yellow.g - blue.g) * (distanceTravelled - 1)),
          b: yellow.b + (Math.abs(yellow.b - blue.b) * (distanceTravelled - 1))
        }
      } else { // 0° to 180°
        newColors = {
          r: blue.r + (Math.abs(yellow.r - blue.r) * distanceTravelled),
          g: blue.g + (Math.abs(yellow.g - blue.g) * distanceTravelled),
          b: blue.b - (Math.abs(yellow.b - blue.b) * distanceTravelled)
        }

      }

      this.setState({
        previousScrollPosition: scrollPosition, 
        rotation: newRotation, smileColor: 
        newColors
      });

      //set a new timeout for the defined duration
      this._timeout = setTimeout(() => {
        this._timeout = null;
        this.resetComponent();
      },idleTime);
     } else {
       //this was the first manual scroll.
       this.setState({hasManuallyScrolled: true});
     }
  }

  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    //let RGBblue = "rgb(" + blue.r + "," + blue.g + "," + blue.b + ")";

    
    let transform = "rotate(" + (this.state.rotation) + ", " + cX + ", " + cY + ")";

    let smileColor = this.getSmileColor(this.state.rotation);
    /*
    let strokeColor = "rgb(" + 
        Math.round(this.state.smileColor.r) + "," + 
        Math.round(this.state.smileColor.g) + "," + 
        Math.round(this.state.smileColor.b) + ")";
        */
    let strokeColor = "rgb(" + 
        Math.round(smileColor.r) + "," + 
        Math.round(smileColor.g) + "," + 
        Math.round(smileColor.b) + ")";
    
    //let svg_height = offsetY + 3*r;
    //let svg_height = cY + (r * 1.5);
    //let svg_width = offsetX +  2 * r;

    //let viewbox = "0 0 " + svg_width + " " + svg_height;

    return (
            <div id='scrolling-eff'>
          <svg id='eff_svg' version="1.1" xmlns="http://www.w3.org/2000/svg"
            viewBox={viewbox}
          >
          <line id="eff-vertical-bar" x1={x0} y1={y0} x2={x1} y2={y1} stroke="blue" strokeWidth={stroke} />
          <line id="eff-horizontal-bar" x1={x1mod} y1={y1mod} x2={x2} y2={y2mod} stroke="blue" strokeWidth={strokeTop}/>

          <path d={smileArc} fill="transparent"
            strokeWidth={strokeAngle}
            stroke={strokeColor}
            transform={transform}
      />
          </svg> 
      </div>

    )
  }
}

export default ScrollingEff;