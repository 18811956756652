import ReactGA from 'react-ga';

import { getSetting } from './config';

export function logPageView() {
  ReactGA.set({ page: window.location.pathname + window.location.search });
  ReactGA.pageview(window.location.pathname + window.location.search);
}

export function trackStartReading(issue) {
  ReactGA.event( {
    category: 'Reading',
    action: 'Scrolled To Content',
    label: issue
  });
  
}

export function trackReadToHalf(issue) {
  ReactGA.event( {
    category: 'Reading',
    action: 'Scrolled To Half',
    label: issue
  });
}

export function trackReadToEnd(issue) {
  ReactGA.event( {
    category: 'Reading',
    action: 'Scrolled To End',
    label: issue
  });

}

export function trackEvent(category, action, label) {
  ReactGA.event( {
    category: category,
    action: action,
    label: label
  });
}

export function initAnalytics() {
  ReactGA.initialize('UA-93237276-1', {debug: getSetting('ga_debug'), anonymizeIp: true});
}