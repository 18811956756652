import React from 'react';

import { Container, Row, Col, Button } from 'reactstrap';

import Link from '../net/Link';

/**
 * Displays a notice about used cookies in the footer.
 */
class CookieNotice extends React.Component {

  constructor(props) {
    super(props);
    this.ackCookie = this.ackCookie.bind(this);
  }

  ackCookie(event) {
    event.preventDefault();
    this.props.onAckCookie();
  }

  componentDidMount() {
    if (this.props.afterLoad) {
      this.props.afterLoad();
    }
  }

  render() {
    return (
      <div id="cookie-notice">
        <Container fluid className="w-80">
          <Row>
            <Col>
            <p className="small"><span className="cookie-notice-headline">Cookie Notification:</span> I rely on some basic information about how my website is used to continously improve it. That's why I'm using a common analytics software to collect usage data in an anonymous way. I do NOT track, collect or store any personal information about you! Please help me by letting me enable this software, or <Link to='/imprint'>read more here</Link>.
            </p>
            </Col>
            <Col xs="auto" className="my-auto">
              <Button outline color="secondary" size="sm" onClick={this.ackCookie}>Sure thing, glad I can help!</Button>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default CookieNotice;